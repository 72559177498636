import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import LogoImg from './../../content/assets/intelius-logo.png'
import { useModalContextToggle } from './modalContext'

const Header = () => {

    const [isMenuOpen, setMenuOpen] = useState(false)
    const toggleModal = useModalContextToggle()

    const toggleMenu = ()=> {
        setMenuOpen((prevState)=> {
            return !prevState
        })
    }

    return (
        <div className="header-class">
            <div className="uk-container uk-grid align-center">
                <div className="uk-width-1-3@m">
                    <a href="/"><img src={LogoImg} alt="Intelius" /></a>
                    <div className="menu-icon" onClick={toggleMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`nav-menu no-select ${isMenuOpen? '' : 'hidden'}`} id="mobileMenu">
                        <ul>
                            <li><a href="/learn-more">Learn More</a></li>
                            <li><a href="/faq">FAQ</a></li>
                            <li>
                                <div onClick={toggleModal} className="uk-button uk-button-default" uk-toggle="#signup-modal">SIGN UP NOW</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="uk-width-2-3@m uk-visible@m">
                    <ul className="uk-navbar-nav uk-align-right uk-margin-remove uk-padding-remove">
                        <li><a href="/learn-more">Learn More</a></li>
                        <li><a href="/faq">FAQ</a></li>
                        <li><div onClick={toggleModal} className="uk-button uk-button-default" uk-toggle="#signup-modal">SIGN UP NOW</div></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Header